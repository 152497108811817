<template>
  <div class="page-header row no-gutters py-4">
    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
      <span v-if="back" class="text-uppercase page-subtitle" @click="goBack" style="cursor:pointer;"><i class="material-icons">keyboard_backspace</i> Ga terug</span>
      <span v-else class="text-uppercase page-subtitle" v-html="subtitle"></span>
      <h3 class="page-title" v-html="title"></h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    back: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
