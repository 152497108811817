import axios from '../index'

const model = '/customers'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (customer) => {
        return axios({
            url: `${model}/${customer.id}`
        })
    },
    create: async (firstName, lastName, sales, gender, birthDate) => {
        let salesIri = []
        sales.forEach((sale) => {
            salesIri.push(`/sales/${sale.id}`)
        })
        return axios({
            url: `${model}`,
            data: {
                first_name: firstName,
                last_name: firstName,
                sales: salesIri,
                gender: gender,
                birth_date: birthDate
            }
        })
    },
    delete: async (customer) => {
        return axios({
            url: `${model}/${customer.id}`,
            method: 'DELETE'
        })
    }
}