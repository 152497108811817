import axios from '../index'

const model = 'models'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (id) => {
        return axios({
            url: `${model}/${id}`
        })
    },
    getByMake: async (id) => {
      return axios({
          url: `${model}?make.id=${id}`
      })
    },
    create: async (name, make) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                name: name,
                make: `/makes/${make}`
            }
        })
    },
    update: async (name, make, modelId) => {
        return axios({
            url: `${model}/${modelId}`,
            method: 'PUT',
            data: {
                name: name,
                make: `/makes/${make}`
            }
        })
    },
}
