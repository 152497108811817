export default {
    namespaced: true,
    state: {
        companyLogo: 'data'
    },
    actions: {
        setCompanyLogo({commit, state}, company) {
            switch (company){
                case 'Vaartland':
                    commit('setCompanyLogo', '/img/logo.png')
                    break
                case 'Ototax':
                    commit('setCompanyLogo', '/img/ototax.png')
                    break
                case 'AutoSchadeland':
                    break

            }
        }
    },
    mutations: {
        setCompanyLogo(state, logo) {
            state.companyLogo = logo
        }
    }
};
