const index = () => import('@/modules/vehicleOptions/index');
const home = () => import('@/modules/vehicleOptions/pages/index');
const vehicleOptionsByCategory = () => import('@/modules/vehicleOptions/pages/vehicleOptionsByCategory');

export default {
  path: 'voertuig-optie-mapper',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'optionsOverview',
      path: '/',
      component: home,
    },
    {
      name: 'vehicleOptionsByCategory',
      path: 'categorie/:category_id',
      component: vehicleOptionsByCategory,
    }
  ]
}
