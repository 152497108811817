<script>
export default {
  name: 'baseArrayCRUD',
  data() {
    return {
      options: [],
      newOptions: [],
      removedOptions: []
    }
  },
  methods: {
    addOptions(options){
      options.forEach(option => {
        option.defaultValue = option.name.value
        option.disabled = true
      })

      this.options = options
    },
    removeOption({index, array}) {
      if (index > -1) {
        this.removedOptions.push(this.options[index])
        array.splice(index, 1);
      }
    },
    editOption(option) {
      option.disabled = !option.disabled
    },
    resetOption(option) {
      option.name.value = option.defaultValue
      option.disabled = true
    },
    reAddOption({index, array}) {
      if (index > -1) {
        this.options.push(this.removedOptions[index])
        array.splice(index, 1);
      }
    },
    removeNewOption({index, array}) {
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    addNewOptions(){

    }
  },
  computed: {
    getOptions: {
      get() {
        let options = []
        this.options.forEach((option) => {
          !!option.name.value ? options.push(options) : null
        })
        return options
      }
    },
    changedOptions: {
      get() {
        let options = []
        this.options.forEach((option) => {
          if (option.defaultValue !== option.name.value) {
            options.push(options)
          }
        })
        return options
      }
    }
  }
}
</script>