import axios from '../index'

const model = '/vehicle_option_categories'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (vehicleOptionCategory) => {
        return axios({
            url: `${model}/${vehicleOptionCategory.id}`
        })
    }
}