import Vue from "vue"

const validate = (obj, successFunc, errorFunc) => {
    let isValid = true
    Object.keys(obj).forEach((data) => {
        if (obj[data].hasOwnProperty('$isValid') && obj[data].hasOwnProperty('validations')) {
            if (!obj[data]?.$isValid && !!obj[data].validations) {
                isValid = false
            }
        }
    })

    isValid ? successFunc(extract(obj)) : errorFunc({valid: false})
}

const extract = (form) => {
    let rObj = {}
    Object.keys(form).forEach((data) => {
        rObj[data] = form[data].value
    })
    return rObj
}

const inject = (object, data) => {
    Object.keys(object).forEach((key) => {
        object[key].value = data[key]
    })

    return object
}

Vue.prototype.$v = {
    validate,
    extract,
    inject
}
