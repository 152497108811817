import Vue from 'vue'
import store from '@/store'
import _ from 'lodash'

function generateMenu(routers, modules) {
    let allMenus = require.context('@/modules/', true, /menu.js$/)
    let sidebarMenu = []

    modules.forEach((mod) => {
        let menu = _.clone(allMenus(`./${mod}/menu.js`).default[0])
        let router = routers.find((route) => {
            return route.children[0].name.split('/')[2] === mod
        })
        if (menu.hasOwnProperty('items')) {
            menu.items.forEach((men) => {
                router.children.forEach((rou) => {
                    if (rou.name.includes(men.route)) {
                        men.to = rou.name
                    }
                })
            })
        } else {
            router.children.forEach((rou) => {
                if (rou.name.includes(menu.to.route)) {
                    menu.to.route = rou.name
                }
            })
        }

        if (router.hasOwnProperty('roles')) {
            if (Vue.prototype.$can(router.roles)) {
                sidebarMenu.push(menu)
            }
        }
    })

    store().dispatch('menu/setMenu', {items: sidebarMenu, options: {backButton: true}})
}

export {
    generateMenu
}
