<template>
  <div>
    <button class="btn btn-primary v-input" @click="remove"><i class="material-icons">delete</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'baseArrayCRUDCreateButtons',
  props: {
    index: {
      required: true
    },
    array: {
      required: true,
      type: Array
    }
  },
  methods: {
    remove(){
      this.$emit('remove', {index: this.index, array: this.array})
    }
  }
}
</script>