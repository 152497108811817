import Vue from 'vue'

import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'

// Translations
import translationsNL from './lang/nl-NL'
const store = new Vuex.Store();

Vue.use(Vuex);
Vue.use(vuexI18n.plugin, store);

Vue.i18n.add('nl', translationsNL);

Vue.i18n.set('nl');
