import Vue from 'vue'

Vue.filter('unknown', function (value, text) {
  if (!value) return 'onbekend';
  if (value === '0' || value === 0) return 'onbekend';
  value = value.toString();
  if (typeof text !== 'undefined') {
    return typeof text !== 'undefined' ? text : ''
  }
  return value
});
