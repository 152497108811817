const index = () => import('@/modules/makeAndModel/index');
const home = () => import('@/modules/makeAndModel/pages/index');
const model = () => import('@/modules/makeAndModel/pages/model');
const models = () => import('@/modules/makeAndModel/pages/models');

export default {
  path: 'merk-en-model',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'merken',
      path: '/',
      component: home,
    },
    {
      name: 'modellen',
      path: ':make_id/models',
      component: models,
    },
    {
      name: 'model',
      path: ':make_id/models/:model_id',
      component: model,
    }
  ]
}
