const index = () => import('@/modules/example/index');
const home = () => import('@/modules/example/pages/index');

export default {
  path: 'Voorbeeld',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'voorbeeld',
      path: '/',
      component: home,
    },
  ]
}
