const index = () => import('@/modules/userManagement/index.vue');
const usersOverview = () => import('@/modules/userManagement/pages/usersOverview');
const rolesOverview = () => import('@/modules/userManagement/pages/rolesOverview');
const userOverview = () => import('@/modules/userManagement/pages/userOverview');
const functionsOverview = () => import('@/modules/userManagement/pages/functionsOverview');

export default {
  path: 'user-management',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'overview',
      path: '',
      component: usersOverview,
    },
    {
      name: 'user',
      path: 'gebruiker/:user_id',
      component: userOverview,
    },
    {
      name: 'roles',
      path: 'rollen',
      component: rolesOverview,
    },
    {
      name: 'function',
      path: 'functies',
      component: functionsOverview,
    }
  ]
}
