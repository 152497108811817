import Vue from 'vue'

Vue.filter('roleFormatter', function (value) {
    let role = value.replace('ROLE_', '')
    role = role.replaceAll('_', ' ')
    role = role.toLowerCase()
    role = role.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return role
})
