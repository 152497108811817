export default {
    version: process.env['VUE_APP_VERSION'],
    photoStudio: {
        photoBaseUrl: process.env['VUE_APP_PHOTOSTUDIO_BASE_URL'],
        apiEndpoint: process.env['VUE_APP_PHOTOSTUDIO_API_ENDPOINT'],
        mercureEndpoint: process.env['VUE_APP_PHOTOSTUDIO_MERCURE_ENDPOINT'],
    },
    sentry: {
        dsn: process.env['VUE_APP_SENTRY_DSN'],
        environment: process.env['VUE_APP_SENTRY_ENVIRONMENT']
    }
}
