import axios from '../index'

const model = 'usps'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (usp) => {
        return axios({
            url: `${model}/${usp.id}`
        })
    },
    create: async (usp) => {
        return axios({
            url: `${model}`,
            data: {
                vehicle: `/vehicle/${usp.id}`,
            }
        })
    },
    update: async (usp) => {
        return axios({
            url: `${model}/${usp.id}`,
            data: {
                name: name,
            }
        })
    },
    delete: async (usp) => {
        return axios({
            url: `${model}/${usp.id}`,
            method: 'DELETE'
        })
    }
}