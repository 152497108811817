import axios from '../index'

const model = 'vehicles'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    getByLicensePlate: async (licensePlate) => {
        return axios({
            url: `${model}?license_plate=${licensePlate}`
        })
    },
    getByUUID: async (uuid) => {
        return axios({
            url: `${model}/${uuid}`
        })
    },
    create: async (licensePlate) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                licensePlate: licensePlate,
                //@TODO remove everything under this line if fixed!
                mileage: 15000,
                isMargin: false,
            }
        })
    },
    setOptionGroups: async (vehicleID, vehicleOptionGroupArray) => {
        // Generate IRI
        let groups = []
        vehicleOptionGroupArray.forEach(group => {
            groups.push(`/vehicle_option_groups/${group.id}`)
        })

        return axios({
            url: `${model}/${vehicleID}`,
            method: 'PUT',
            data: {
                vehicle_option_groups: groups
            }
        })
    }
}
