export default [
  {
    title: 'Account',
    htmlBefore: '<i class="material-icons">person</i>',
    htmlAfter: '',
    items: [
      {
        title: 'Personalia',
        route: 'sell-1',
      },
      {
        title: 'Functiegegevens',
        route: 'sell-3',
      },
      {
        title: 'Rooster',
        route: 'sell-3',
      },
      {
        title: 'Bedrijfsauto',
        route: 'sell-3',
      },
      {
        title: 'Vrije dagen',
        route: 'sell-3',
      },
      {
        title: 'Extra dagen',
        route: 'sell-3',
      },
      {
        title: 'Balans',
        route: 'sell-3',
      },
      {
        title: 'Ziekte & Verzuim',
        route: 'sell-3',
      },
      {
        title: 'Rollen',
        route: 'sell-3',
      },
      {
        title: 'Rollens',
        route: 'sell-3',
      }
    ]
  }
]
