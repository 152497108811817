import axios from '../index'

const model = '/vehicle_options'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (vehicleOption) => {
        return axios({
            url: `${model}/${vehicleOption.id}`
        })
    },
    getVehicleOptions(vehicle){
      return axios({
          url: `${model}?vehicle.id[]=${vehicle.id}`
      })
    },
    getOptionsWithoutGroup(){
        return axios({
            url: `${model}?exists[group]=false`
        })
    },
    create: async (name, group, description, translation, ignored, optionCode) => {
        return axios({
            url: `${model}`,
            data: {
                name: name,
                group: `/vehicle_option_groups/${group.id}`,
                description: description,
                translation: translation,
                ignored: ignored,
                optionCode: optionCode
            }
        })
    },
    setGroup: async (vehicleOption, group) => {
        return axios({
            url: `${model}/${vehicleOption.id}`,
            method: 'PUT',
            data: {
                group: `/vehicle_option_groups/${group.id}`,
            }
        })
    },
    delete: async (vehicleOption) => {
        return axios({
            url: `${model}/${vehicleOption.id}`,
            method: 'DELETE'
        })
    }
}