import Vue from 'vue'
import VueRouter from 'vue-router'

import generateModules from './lib/generate-modules'
import {generateMenu} from './lib/generate-menu'
import staticRoutes from "./static-routes"

Vue.use(VueRouter)

// Find /dashboard to include the modules on ;)
let dashboards = staticRoutes.find((r) => {
    return r.path === '/dashboard'
})

const router = async () => {
    for (const dashboard of dashboards.children) {
        let component = (await dashboard.component()).default
        if (component.hasOwnProperty('modules')) {
            dashboard.children = generateModules(component, dashboard.path)
        }
    }

    let router = new VueRouter({
        mode: 'history',
        routes: [
            ...staticRoutes
        ]
    })

    router.beforeEach((to, from, next) => {
        let path = to.path.split('/')

        if (path[1] === 'dashboard') {
            dashboards.children.forEach(async (dashboard) => {
                if (dashboard.path === path[2]) {
                    let module = (await dashboard.component()).default

                    let routes = router.options.routes.find((staticRoutes) => {
                        return staticRoutes.path === '/dashboard'
                    }).children.find((dashboardChild) => {
                        return dashboardChild.path === path[2]
                    })

                    generateMenu(routes.children, module.modules)
                }
            })
        }
        next()
    })

    router.onError((err) => {
        console.log(err)
    })

    return router
}

export {router}
