import Vue from "vue"

//
function goToModule(router) {
    if (router.path === '' && !Object.prototype.hasOwnProperty.call(router, "children")) {
        return router.name
    } else if (Object.prototype.hasOwnProperty.call(router, "children")) {
        for (let route in router.children) {
            if (router.children.hasOwnProperty(route)) {
                if (router.children[route].path === '') {
                    if (Object.prototype.hasOwnProperty.call(router.children[route], "children")) {
                        let routeName = goToModule(router.children[route])
                        return routeName !== null ? routeName : {
                            path: router.children[route].path,
                            name: router.children[route].name
                        }
                    } else {
                        return {path: router.children[route].path, name: router.children[route].name}
                    }
                }
            }
        }
    }
    return null
}

function goToModuleRoute(router, path) {
    if (router.path === '' && !Object.prototype.hasOwnProperty.call(router, "children")) {
        return router.name
    } else if (Object.prototype.hasOwnProperty.call(router, "children")) {
        for (let route in router.children) {
            if (router.children.hasOwnProperty(route)) {
                if (router.children[route].name.endsWith(path)) {
                    return router.children[route].name
                } else {
                    if (Object.prototype.hasOwnProperty.call(router.children[route], "children")) {
                        let routeName = goToModuleRoute(router.children[route], path)
                        return routeName !== null ? routeName : {
                            path: router.children[route].path,
                            name: router.children[route].name
                        }
                    }
                }
            }
        }
    }
    return null
}

Vue.prototype.$goTo = (pathName) => {
    // let path = window.location.pathname.split('/')
    // Vue.$router.push({name: `dashboard/${path[2]}/${path[3]}/${pathName}`})
    // console.log(Vue.prototype.$eventHub)
}

Vue.prototype.$goToModule = (router) => {
    router.push({name: goToModule(router).name})
}

Vue.prototype.$goToModuleRoute = (router, path) => {
    // router.push({ name: goToModuleRoute(router, path) })
    return goToModuleRoute(router, path)
}