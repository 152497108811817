import axios from '../index'

const model = '/colors'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (id) => {
        return axios({
            url: `${model}/${id}`
        })
    }
}