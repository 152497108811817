export default [
  {
    title: 'Gebruikers beheer',
    htmlBefore: '<i class="material-icons">supervisor_account</i>',
    htmlAfter: '',
    items: [
      {
        title: 'Personalia',
        route: 'overview',
      },
      {
        title: 'Rollen',
        route: 'roles',
      },
      {
        title: 'Functies',
        route: 'function',
      }
    ]
  }
]
