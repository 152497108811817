const index = () => import('@/modules/photoStudio/index.vue');
const photobooth1 = () => import('@/modules/photoStudio/pages/photobooth-1.vue');
const photobooth2 = () => import('@/modules/photoStudio/pages/photobooth-2.vue');
const sort = () => import('@/modules/photoStudio/pages/sort');
const vehicleStatus = () => import('@/modules/photoStudio/pages/vehicle-status');

export default {
    roles: [
        "ADMIN",
        "INTAKE",
    ],
    path: 'foto-studio',
    component: index,
    children: [
        {
            name: 'vehicle-status',
            path: '',
            component: vehicleStatus,
        },
        {
            name: 'photobooth-1',
            path: 'photobooth-1',
            component: photobooth1,
        },
        {
            name: 'photobooth-2',
            path: 'photobooth-2',
            component: photobooth2,
        },
        {
            name: 'sort',
            path: 'sort/:licensePlate',
            component: sort,
        }
    ]
}
