export default {
  namespaced: true,
  state: {
    notifications: [
      {
        id: 12312347,
        icon: 'anchor',
        category: 'Analytics',
        message: 'Example message 1.'
      },
      {
        id: 12312347,
        icon: 'anchor',
        category: 'Analytics',
        message: 'Example message 2.'
      },
      {
        id: 12312347,
        icon: 'anchor',
        category: 'Analytics',
        message: 'Example message 2.'
      }
    ]
  },
  getters: {
    notifications: state => {
      return state.notifications
    }
  },
  actions: {
    addNotification({commit, state}, notification) {
      commit('addNotification', notification)
    }
  },
  mutations: {
    addNotification: (state, notification) => {
      state.notifications.push(notification)
    }
  }
};
