import Vue from 'vue'
import axios from './index'
import photoStudioApi from './services/photostudio.requests'
import webservicePhotoStudio from './services/webservice/photoStudio.requests'

let requests = {
    login: (email, password) => {
        return axios({
            url: `login`,
            method: 'post',
            data: {email: email, password: password},
        });
    },
    logout: () => {
        return axios({
            url: `/logout`,
        });
    },
    me: () => {
        return axios({
            url: `/me`
        });
    },
}

// Auto wire models
let models = require.context('@/requests/models/', true, /\.model.js$/);
models.keys().forEach(filename => {
    let model = filename.split(/[./]/)[2]
    requests[model] = models(filename).default
});

requests.$photoStudio = photoStudioApi

requests.$intranet = {
    webservice: webservicePhotoStudio
}

Vue.prototype.$api = requests

export {
    axios
}
