import axios from 'axios';
import qs from 'qs'

const axiosInstance = axios.create({
    baseURL: 'https://webservice.vaartland.nl',
    withCredentials: false
})

export default {
    photoStudio: {
        list: async () => {
            return axiosInstance({
                url: `/api/photo-studio/`,
                method: 'GET',
            })
        },
        get: async (licensePlate) => {
            return axiosInstance({
                url: `/api/photo-studio/${licensePlate}`,
                method: 'GET',
            })
        },
        photos: {
            setPosition: async (photoID, position) => {
                return axiosInstance({
                    url: `/api/photo-studio/photo/${photoID}/position`,
                    method: 'PUT',
                    data: qs.stringify({
                        position: position
                    })
                })
            },
            setSorted: async (vehicleID) => {
                return axiosInstance({
                    url: `/api/photo-studio/set-sorted/${vehicleID}`,
                    method: 'PUT'
                })
            },
            setType: async (photoID, type) => {
                return axiosInstance({
                    url: `/api/photo-studio/photo/${photoID}/type`,
                    method: 'PUT',
                    data: qs.stringify({
                        type: type
                    })
                })
            },
            delete: async (photoID) => {
                return axiosInstance({
                    url: `/api/photo-studio/photo/${photoID}/delete`,
                    method: 'DELETE'
                })
            }
        }
    }
}
