import axios from '../index'

const model = 'expected_damage_reports'

export default {
    TECHNICAL: 'technical',
    OPTICAL: 'optical',

    /**
     *
     * @returns {AxiosPromise<any>}
     */
    list: async () => {
        return axios({
            url: `${model}`
        })
    },

    /**
     *
     * @param type [optical, technical]
     * @param description
     * @param purchase
     * @param amount
     * @returns {AxiosPromise<any>}
     */
    create: async (type, description, purchase, amount) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                type: type,
                description: description,
                purchase: `/purchases/${purchase.id}`,
                estimated_amount: amount
            }
        })
    }
}
