const index = () => import('@/modules/supplier/index');
const suppliersOverview = () => import('@/modules/supplier/pages/suppliersOverview');
const supplierOverview = () => import('@/modules/supplier/pages/supplierOverview');
const supplierAddress = () => import('@/modules/supplier/pages/supplierAddress');

export default {
  path: 'leveranciers',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'overview',
      path: '/',
      component: suppliersOverview,
    },
    {
      name: 'leverancier',
      path: ':supplier_id',
      component: supplierOverview
    },
    {
      name: 'address',
      path: ':supplier_id/adres/:address_id',
      component: supplierAddress
    },
  ]
}
