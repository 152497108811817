import axios from 'axios'
import config from "@/config";

// Images
const imagesByType = async (type) => {
    try {
        let {data} = await axios.get(`${config.photoStudio.apiEndpoint}/images/?type=${type}`)
        return data
    } catch (e) {
        throw e
    }
}
const imagesConfirm = async (licensePlate, type) => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/images/confirm`,
            params: {
                licenseplate: licensePlate,
                type: type
            }
        })
        return data
    } catch (e) {
        throw e
    }
}
const imagesDelete = async (path) => {
    try {
        let {data} = await axios({
            method: 'POST',
            url: `${config.photoStudio.apiEndpoint}/images/delete`,
            data: {path: path}
        })
        return data
    } catch (e) {
        throw e
    }
}
const imagesOrder = async (licensePlate) => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/images/order/${licensePlate}`
        })
        return data
    } catch (e) {
        throw e
    }
}
const imageOrderSet = async (licensePlate, photos) => {
    try {
        let {data} = await axios({
            method: 'POST',
            url: `${config.photoStudio.apiEndpoint}/images/order/${licensePlate}`,
            data: photos
        })
        return data
    } catch (e) {
        throw e
    }
}

// Process
const process = async () => {
    try {
        let {data} = await axios.get(`${config.photoStudio.apiEndpoint}/process/`)
        return data
    } catch (e) {
        throw e
    }
}
const processStart = async (licensePlate) => {
    try {
        let {data} = await axios({
            method: 'POST',
            url: `${config.photoStudio.apiEndpoint}/process/start`,
            data: {
                licenseplate: licensePlate
            }
        })
        return data
    } catch (e) {
        throw e
    }
}
const processDelete = async (licensePlate) => {
    try {
        let {data} = await axios({
            method: 'POST',
            url: `${config.photoStudio.apiEndpoint}/process/delete`,
            data: {
                licenseplate: licensePlate
            }
        })
        return data
    } catch (e) {
        throw e
    }
}
const processFinish = async (licensePlate) => {
    try {
        let {data} = await axios({
            method: 'POST',
            url: `${config.photoStudio.apiEndpoint}/process/finish`,
            data: {
                licenseplate: licensePlate
            }
        })
        return data
    } catch (e) {
        throw e
    }
}

// Utils
const platformTurn = async (degrees) => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/platform/turn/${degrees}`,
        })
        return data
    } catch (e) {
        throw e
    }
}
const licensePlatesInProcess = async (search) => {
    try {
        let {data} = await axios({url: `${config.photoStudio.apiEndpoint}/licenseplate/${search}`})
        return data
    } catch (e) {
        throw e
    }
}
const testCapture = async () => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/images/test-capture`
        })
        return data
    } catch (e) {
        throw e
    }
}

const restartSortingService = async () => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/reset/run-sorting-service`
        })
        return data
    } catch (e) {
        throw e
    }
}

const restartPlc = async () => {
    try {
        let {data} = await axios({
            method: 'GET',
            url: `${config.photoStudio.apiEndpoint}/reset/reset-plc`
        })
        return data
    } catch (e) {
        throw e
    }
}

export {
    imagesByType,
    imagesConfirm,
    imagesDelete,
    imagesOrder,
    imageOrderSet,
    //
    process,
    processStart,
    processDelete,
    processFinish,
    //
    platformTurn,
    licensePlatesInProcess,
    testCapture,
    restartSortingService,
    restartPlc
}

