export default [
  {
    title: 'Leveranciers',
    to: {
      route: 'overview',
    },
    htmlBefore: '<i class="material-icons">store</i>',
    htmlAfter: '',
  }
]
