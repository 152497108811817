export default [
  {
    title: 'Aankoop',
    htmlBefore: '<i class="material-icons">shopping_cart</i>',
    htmlAfter: '',
    items: [
      {
        title: 'Aankoop aanmaken',
        route: 'overview',
      },
      {
        title: 'List',
        route: 'list',
      }
    ]
  }
]
