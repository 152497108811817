<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    let currentPath = window.location.pathname
    this.$api.me()
        .then((response) => {
          this.route(currentPath, response)
        })
        .catch(() => {
          this.route(currentPath, {status: 400, data: null})
        })
  },
  methods: {
    route(currentPath, {status, data}) {
      if (currentPath === '/' && status === 200) {
        this.$store.dispatch('user/setUser', data)
        this.$router.push({name: 'home'})
      } else {
        if (status === 403) {
          this.$router.push({name: 'login'})
        }
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
