<template>
    <div :class="sizes">
        <div class="cards">
            <div class="border-bottom card-header" v-if="hasHeader">
                <div style="display: flex; align-items: center; justify-content: space-between">
                    <h6 class="m-0">{{ header }}</h6>
                    <div>
                        <button
                            v-for="action in actions"
                            class="btn btn-primary"
                            @click="action.fn()">
                            <span v-html="action.icon"></span>
                        </button>
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column card-body" v-if="!fluid">
                <slot/>
            </div>
            <slot v-else/>
            <!--      <div class="border-top card-footer">-->
            <!--        <div class="row">-->
            <!--          <button class="btn btn-primary" type="submit">View All Comments</button>-->
            <!--        </div>-->
            <!--      </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'vCard',
    props: {
        col: {
            type: String,
            required: false
        },
        header: {
            type: String,
            required: false
        },
        actions: {
            required: false,
            type: Array
        },
        fluid: {
            required: false,
            type: Boolean,
            default: false
        },
    },
    created() {
    },
    computed: {
        hasHeader() {
            return !!this.$props.header
        },
        sizes() {
            let data = {
                '12': [
                    '12', // <576px
                    '12', // ≥576px
                    '12', // ≥768px
                    '12', // ≥992px
                    '12' // ≥1200px
                ],
                '10': [
                    '12',
                    '12',
                    '12',
                    '10',
                    '10'
                ],
                '9': [
                    '12',
                    '12',
                    '12',
                    '9',
                    '9'
                ],
                '8': [
                    '12',
                    '12',
                    '12',
                    '8',
                    '8'
                ],
                '6': [
                    '12',
                    '12',
                    '12',
                    '6',
                    '6'
                ],
                '4': [
                    '12',
                    '12',
                    '12',
                    '4',
                    '4'
                ],
                '3': [
                    '12',
                    '12',
                    '12',
                    '3',
                    '3'
                ]
            }
            return `col-${data[this.$props.col][0]} col-sm-${data[this.$props.col][1]} col-md-${data[this.$props.col][2]} col-lg-${data[this.$props.col][3]} col-xl-${data[this.$props.col][4]}`
        }
    }
}
</script>

<style scoped>
.cards {
    background-color: #FFF;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: scroll;
}

.cards::-webkit-scrollbar {
    display: none;
}

button {
    margin-right: 10px;
}
</style>
