import config from '@/config'
import {
    imagesConfirm,
    imagesDelete,
    imagesOrder,
    imageOrderSet,
    process,
    processStart,
    processDelete,
    processFinish,
    platformTurn,
} from "@/modules/photoStudio/requests/photostudio.request";

const initializeState = () => {
    return {
        process: [],
        streams: {
            sessionStream: {
                eventSource: null,
                connected: false,
                event: {
                    message: null,
                    licensePlate: null,
                }
            },
            processStream: {
                eventSource: null,
                connected: false,
                event: {
                    licensePlate: null,
                    type: null,
                    message: null
                }
            }
        }
    }
}

export default {
    namespaced: true,
    state: initializeState(),
    getters: {
        exteriorOpenDoors: state => {
            return state.streams.sessionStream.event.message === 'DoorsClosedFinished'
        },
        isExteriorDone: state => {
            return state.streams.sessionStream.event.message === 'Finish'
        },
        areStreamsConnected: state => {
            return state.streams.sessionStream.connected &&
                state.streams.processStream.connected
        },
        processEvent: state => {
            return state.streams.processStream.event
        },
        isErrorInProcess: state => {
            let isErr = false
            state.process.forEach((process) => {
                if (!isErr && process.connection_status_error) {
                    isErr = true
                }
            })
            return isErr
        },
        canStartNewProcess: (state, getters) => {
            return (state.streams.sessionStream.event.message === 'Finish' || state.streams.sessionStream.event.message === null) &&
                state.streams.processStream.event.type === null && !getters.isErrorInProcess
        }
    },
    actions: {
        async setupStreams({dispatch, commit}) {
            commit('resetState')
            await dispatch('refreshProcess')
            dispatch('setupSessionStream')
            dispatch('setupProcessStream')

            return true
        },
        setupSessionStream({commit, dispatch}) {
            let eventSource = new EventSource(`${config.photoStudio.mercureEndpoint}?topic=session`, {withCredentials: true})
            commit('setEventSource', {stream: 'sessionStream', eventSource: eventSource})

            eventSource.onmessage = event => {
                let e = JSON.parse(event.data)
                commit('setSessionStreamEvent', e)
                if (e.message === 'DoorsClosedFinished') {
                    commit('clearProcessStream')
                }
                if (e.message === 'Finish') {
                    dispatch('refreshProcess')
                    commit('clearProcessStream')
                }
            }
            eventSource.onopen = () => {
                commit('setStreamIsConnected', {stream: 'sessionStream', connected: true})
            }
            eventSource.onerror = () => {
                commit('setStreamIsConnected', {stream: 'sessionStream', connected: true})
            }
        },
        setupProcessStream({commit, dispatch}) {
            let eventSource = new EventSource(`${config.photoStudio.mercureEndpoint}?topic=process`, {withCredentials: true})
            commit('setEventSource', {stream: 'processStream', eventSource: eventSource})

            eventSource.onmessage = event => {
                commit('clearSessionStream')
                commit('setProcessStreamEvent', JSON.parse(event.data))
            }
            eventSource.onopen = () => {
                commit('setStreamIsConnected', {stream: 'processStream', connected: true})
            }
            eventSource.onerror = () => {
                commit('setStreamIsConnected', {stream: 'processStream', connected: true})
            }
        },
        closeStreams({commit, state}) {
            state.streams.sessionStream.eventSource.close()
            commit('setStreamIsConnected', {stream: 'sessionStream', connected: false})
            state.streams.processStream.eventSource.close()
            commit('setStreamIsConnected', {stream: 'processStream', connected: false})
        },
        async refreshProcess({commit}) {
            let data = await process()
            commit('setProcessVehicles', data)

            data.forEach(vehicle => {
                if (vehicle.status === 'DoorsClosedFinished') {
                    commit('setSessionStreamEvent', {
                        message: 'DoorsClosedFinished',
                        licenseplate: vehicle.licenseplate
                    })
                }
            })
        },
        async deletePhoto({state}, {path}) {
            try {
                return await imagesDelete(path)
            } catch (e) {
                alert('Error')
            }
        },
        async getPhotosOrder({state}, {licensePlate}) {
            try {
                return await imagesOrder(licensePlate)
            } catch (e) {
                alert('Foto\'s konden niet worden opgehaald herlaad de pagina')
            }
        },
        async setPhotosOrder({state}, {licensePlate, photos}) {
            try {
                return imageOrderSet(licensePlate, photos)
            } catch (e) {
                alert('Foto\'s konden niet worden opgeslagen...')
            }
        },
        async addProcess({state}, {licensePlate}) {
            try {
                return await processStart(licensePlate.toUpperCase())
            } catch (e) {
                alert('Er is iets fouts gegaan')
            }
        },
        async deleteProcess({state}, {licensePlate}) {
            try {
                return await processDelete(licensePlate)
            } catch (e) {
                alert('er is iets fouts gegaan')
            }
        },
        async finishProcess({state}) {
            try {
                return await processFinish(state.streams.sessionStream.event.licensePlate)
            } catch (e) {
                alert('er is iets fouts gegaan')
            }
        },
        async confirmPhotos({state}, {licensePlate, type}) {
            try {
                return await imagesConfirm(licensePlate, type)
            } catch (e) {
                alert('er is iets fouts gegaan')
            }
        },
        async rotatePlatform({state}, {degrees}) {
            if (degrees > -181 && degrees < 181) {
                try {
                    return await platformTurn(degrees)
                } catch (e) {
                    alert('er is iets fouts gegaan')
                }
            } else {
                alert('maximale rotate is -180 tot 180 ingevoerd: ' + degrees)
            }
        }
    },
    mutations: {
        resetState(state) {
            Object.assign(state, initializeState())
        },
        setEventSource(state, {stream, eventSource}) {
            state.streams[stream].eventSource = eventSource
        },
        setStreamIsConnected(state, {stream, connected}) {
            state.streams[stream].connected = connected
        },
        setProcessVehicles: (state, vehicles) => {
            state.process = vehicles
        },
        setSessionStreamEvent: (state, event) => {
            state.streams.sessionStream.event.message = event.message
            state.streams.sessionStream.event.licensePlate = event.licenseplate
        },
        setProcessStreamEvent(state, event) {
            state.streams.processStream.event.type = event.type
            state.streams.processStream.event.message = event.message
            state.streams.processStream.event.licensePlate = event.licenseplate
        },
        clearSessionStream(state) {
            Object.assign(state.streams.sessionStream.event, initializeState().streams.sessionStream.event)
        },
        clearProcessStream(state) {
            Object.assign(state.streams.processStream.event, initializeState().streams.processStream.event)
        }
    }
};
