<template>
    <div class="v-input">
        <label v-if="!!label">{{ label }}<span v-if="isRequired" class="v-input__required">*</span></label>
        <template v-for="option in options" v-if="option">
            <d-form-radio inline :value="option" :name="name" v-model="value.value">{{ option.name }}</d-form-radio>
        </template>
    </div>
</template>

<script>
import baseInput from "@/components/shared/baseInput";

export default {
    name: 'vRadio',
    extends: baseInput,
    methods: {
        select(e) {
            this.value.value = e
        }
    },
    props: {
        placeholderText: {},
        name: {},
        options: {
            required: true
        }
    }
}
</script>

<style lang="scss" module>
.v-input {
    &__required {
        color: red;
    }
}
</style>
