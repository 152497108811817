export default [
  {
    title: 'Voertuig optie mapper',
    htmlBefore: '<i class="material-icons">style</i>',
    htmlAfter: '',
    to: {
      route: 'optionsOverview',
    },
  }
]
