const login = () => import('@/views/login');
const template = () => import('@/views/template')
const notifications = () => import('@/views/notifications')

// Error
const pageNotFound = () => import('@/views/errors/404')

// Dashboards
const overview = () => import('@/views/dashboards/overview')
const intake = () => import('@/views/dashboards/intake')
const sales = () => import('@/views/dashboards/sales')
const it = () => import('@/views/dashboards/it');
const autoschadeland = () => import('@/views/dashboards/autoschadeland')

// Static pages
const user = () => import('@/views/users/index')

export default [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: notifications
  },
  {
    name: 'profile',
    path: '/user',
    component: user
  },
  {
    path: '/dashboard',
    component: template,
    children: [
      {
        path: '/',
        name: 'home',
        component: overview,
      },
      {
        name: 'sales',
        path: 'inkoop',
        component: sales
      },
      {
        name: 'intake',
        path: 'inname',
        component: intake
      },
      {
        path: 'ict',
        component: it
      },
      {
        path: 'autoschadeland',
        component: autoschadeland
      }
    ]
  },
  // Always on the end!
  { path: "*", component: pageNotFound }
]
