<script>
export default {
  name: 'baseInput',
  props: {
    value: { //v-model
      required: false,
      default: ''
    },
    showErrors: {
      required: false,
      default: false,
    },
    label: {
      required: true
    },
    placeHolderText: {
      required: false,
    },
    removed: {
      required: false,
    },
    disabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      errors: [],
      hasError: false,
      css: ''
    }
  },
  created() {
    this.value?.value ? this.updateValue(this.value.value) : this.updateValue(null)
  },
  watch: {
    'value.value': {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.updateValue(newValue)
        }
      }
    },
    showErrors() {
      this.css = this.hasError ? 'shake-error' : ''
    },
    hasError() {
      this.css = this.hasError && this.showErrors ? 'shake-error' : ''
    }
  },
  computed: {
    isRequired: {
      get() {
        return this.value?.validations?.required
      }
    },
    getType: {
      get() {
        return this.value?.type ? this.value?.type : 'text'
      }
    }
  },
  methods: {
    updateValue(value) {
      this.hasError = this.validate(value)
      this.$emit('input', {
        value: value,
        $isValid: !this.hasError,
        type: this.value?.type,
        validations: this.value?.validations
      })
    },
    validate(value) {
      let hasError = false
      this.errors = []

      if (this.value?.validations) {
        Object.keys(this.value.validations).forEach((data) => {
          let error = !this.value.validations[data].fn(value)
          if (error) {
            hasError = true
            this.errors.push(this.value.validations[data].message(value))
          }
        })
      }

      return hasError
    }
  }
}
</script>

<style type="text/css" lang="scss">
.v-input {
  margin-bottom: 10px;

  input.shake-error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
  }
}


ul.v-error {
  padding: 10px 0 0 20px;
  color: red;
  margin: 0;
}

@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 0.05rem;
  }
  75% {
    margin-left: -0.05rem;
  }
  100% {
    margin-left: 0;
  }
}
</style>
