<template>
  <div>
    <button class="btn btn-primary v-input" v-if="option.disabled" @click="edit">
      <i class="material-icons">edit</i>
    </button>
    <button class="btn btn-primary v-input" v-if="!option.disabled" @click="reset">
      <i class="material-icons">close</i>
    </button>
    <button class="btn btn-primary v-input" @click="remove">
      <i class="material-icons">delete</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'baseArrayCRUDCreateButtons',
  props: {
    option: {
      required: true
    },
    index: {
      required: true
    },
    array: {
      required: true,
      type: Array
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.option)
    },
    reset() {
      this.$emit('reset', this.option)
    },
    remove() {
      this.$emit('remove', {index: this.index, array: this.array})
    }
  }
}
</script>

