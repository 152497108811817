import Vue from 'vue'
import _ from 'lodash'


function _generateRouteNames(viewName, routeName, router) {
    // ReWrite path names
    if (Object.prototype.hasOwnProperty.call(router, 'name')) {
        if (router.name !== '') {
            router.name = `dashboard/${viewName}/${routeName}/${router.name}`
        }
    }

    // Loop through child components if exists to repeat
    if (Object.prototype.hasOwnProperty.call(router, 'children')) {
        for (let index in router.children) {
            if (router.children.hasOwnProperty(index)) {
                router.children[index] = _generateRouteNames(viewName, routeName, router.children[index])
            }
        }
    }

    return router
}

function getRouters(dashboardPath, mod) {
    let allRouters = require.context('../../modules/', true, /router.js$/)
    let returnObj = Object.assign({}, allRouters)
    let routers = returnObj.keys().filter((router) => mod.includes(router.split('/')[1]))

    let router = allRouters(routers).default
    if (router?.roles) {
        // @TODO add correct way to check if user has the group to have access to the module
        if (Vue.prototype.$can(router.roles)) {
            let route = _.cloneDeep(router)

            route = _generateRouteNames(dashboardPath, mod, route)

            return route
        }
    }

    return false
}

function generateModules(dashboard, dashboardPath) {
    let modules = []
    dashboard.modules.forEach((mod) => {
        let routers = getRouters(dashboardPath, mod)
        if (routers) {
            modules.push(routers)
        }
    })
    return modules
}

export default generateModules
