import axios from '../index'

const model = '/accessories'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (accessory) => {
        return axios({
            url: `${model}/${accessory.id}`
        })
    },
    create: async (description, price) => {
        return axios({
            url: `${model}`,
            data: {
                description: description,
                price: price
            }
        })
    },
    delete: async (accessory) => {
        return axios({
            url: `${model}/${accessory.id}`,
            method: 'DELETE'
        })
    }
}