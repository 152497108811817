import Vue from "vue"

let backButton = {
    title: 'Ga terug',
    to: {route: 'home'},
    htmlBefore: '<i class="material-icons">keyboard_backspace</i>'
}

export default {
    namespaced: true,
    state: {
        menu: []
    },
    actions: {
        setMenu({commit, state}, {items, options}) {
            let menu = []

            for (let i = 0; i < items.length; i++) {
                menu.push(items[i])
            }
            if (options.backButton) {
                menu.push(backButton)
            }
            commit('SET_MENU', menu)
            setTimeout(() => {
                Vue.prototype.$eventHub.$emit('menu', menu)
            }, 50)
        },
        addBackButton({commit}) {
            commit('addBackButton')
        }
    },
    mutations: {
        SET_MENU: (state, menu) => {
            state.menu = menu
        }
    }
};
