import axios from '../index'

const model = 'purchases'

export default {
    list: async () => {
        let purchases = await axios({
            url: `${model}`
        })

        for (let purchase of purchases.data) {
            purchase['vehicle'] = (await axios({
                url: purchase.vehicle
            })).data
            purchase['supplier_address'] = (await axios({
                url: purchase.supplier_address
            })).data
        }

        return purchases
    },
    create: async (purchaseTypeId, vehicleId, establishmentId, supplierAddressId, price) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                type: `/purchase_types/${purchaseTypeId}`,
                vehicle: `/vehicles/${vehicleId}`,
                establishment: `/establishments/${establishmentId}`,
                supplier_address: `/supplier_addresses/${supplierAddressId}`,
                total_price: price
            }
        })
    }
}
