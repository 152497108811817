import axios from '../index'

const model = 'fuels'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    }
}