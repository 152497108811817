import Vue from 'vue'

function getMenu(vm) {
    const {menu} = vm.$options
    if (menu) {
        let items = []
        menu.forEach(item => {
            if (item?.roles) {
                if (Vue.prototype.$can(item.roles)) {
                    items.push(item)
                }
            } else {
                items.push(item)
            }
        })
        return items
    }
}

export default {
    created() {
        const menu = getMenu(this)
        if (menu) {
            // Vue.prototype.$can(router.roles)
            this.$store.dispatch('menu/setMenu', {items: menu, options: {backButton: false}})
        }
    }
}