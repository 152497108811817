import Vue from 'vue'
import App from '@/App.vue'
import Config from './config'

// Essentials
import store from '@/store'
import {router} from '@/router'
import '@/requests/requests'

// Style library's
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import ShardsVue from 'shards-vue';
import VModal from 'vue-js-modal'
import Multiselect from 'vue-multiselect'

// Styles library's styles
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scss/shards-dashboards.scss';

ShardsVue.install(Vue)
library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VModal)

Vue.config.productionTip = false;

// load all plugins
const plugins = require.context('@/plugins/', true, /.plugin.js$/);
plugins.keys().forEach(filename => plugins(filename));

// load all filters
const filters = require.context('@/filters/', true, /.filter.js$/);
filters.keys().forEach(filename => filters(filename));

// load all extensions
const extensions = require.context('@/extensions/', true, /.extension.js$/);
extensions.keys().forEach(filename => extensions(filename));

// load all mixins
const mixins = require.context('@/mixins/', true, /.mixin.js$/);
mixins.keys().forEach(filename => Vue.mixin(mixins(filename).default));

// load all (shared) components
const component = require.context('@/components/shared/', true, /.vue$/);
component.keys().forEach(filename => {
    Vue.component(component(filename).default.name, component(filename).default)
});
Vue.component('multiselect', Multiselect)

// Vue-Shard errors message 🤷🏼‍
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
        msg = null;
        vm = null;
        trace = null;
    }
}

router().then(router => {
    new Vue({
        render: h => h(App),
        store,
        router
    }).$mount('#app');
})
