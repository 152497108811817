import axios from '../index'

const model = '/supplier_addresses'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (id) => {
        return axios({
            url: `${model}/${id}`
        })
    },
    getBySupplier: async (id) => {
        return axios({
            url: `${model}/?supplier.id=${id}`
        })
    },
    create: async (postalCode, city, houseNumber, street, country, isDeliveryAddress, phoneNumber, contactName, supplier) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                postalCode: postalCode,
                city: city,
                houseNumber: houseNumber,
                street: street,
                country: `/countries/${country}`,
                isDeliveryAddress: isDeliveryAddress,
                phoneNumber: phoneNumber,
                contactName: contactName,
                supplier: `/suppliers/${supplier}`
            }
        })
    },
    updateAddress: async (postalCode, city, houseNumber, street, country, isDeliveryAddress, address_id) => {
        return axios({
            url: `${model}/${address_id}`,
            method: `PUT`,
            data: {
                postalCode: postalCode,
                city: city,
                houseNumber: houseNumber,
                street: street,
                country: `/countries/${country}`,
                isDeliveryAddress: isDeliveryAddress,
            }
        })
    },
    patch: async () => {

    }
}