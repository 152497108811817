export default {
  // Module Names
  userManagement: 'gebruikers beheer',
  userProfile: 'profiel',
  validations: {
    required: 'Dit veld is verplicht',
    email: 'Dit veld moet een e-mail adres bevatten',
    minLength: 'Minimaal {count} karakters lang zijn',
    maxLength: '',
  }
}
