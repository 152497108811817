<template>
  <div v-if="!disabled">
    <button class="btn btn-primary" @click="validate">Opslaan</button>
  </div>
</template>

<script>
export default {
  name: 'vValidateButton',
  props: {
    disabled: {
      required: false,
    },
    form: {
      required: true
    }
  },
  methods: {
    validate() {
      this.$v.validate(this.form, (form) => {
        this.$emit('valid', form)
      }, () => {
        this.$emit('error', true)
      })
    }
  }
}
</script>