import axios from '../index'

const model = 'makes'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (id) => {
        axios({
            url: `${model}/${id}`
        })
    },
    create: async (name) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                name: name,
            }
        })
    },
    update: async () => {

    },
    delete: async () => {

    },
    patch: async () => {

    }
}