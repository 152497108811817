import axios from '../index'

const model = '/user_functions'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
}