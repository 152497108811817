import axios from '../index'

const model = '/mileage_histories'

export default {
    KILOMETERS: 'km',
    MILES: 'miles',
    /**
     *
     * @returns {Promise<void>}
     */
    list: async () => {

    },

    /**
     *
     * @param id
     * @returns {Promise<void>}
     */
    get: async (id) => {
        return axios({
            url: `${model}`,
        })
    },

    /**
     *
     * @param vehicle
     * @param type
     * @param mileage
     * @returns {AxiosPromise<any>}
     */
    create: async (vehicle, type, mileage) => {
        return axios({
            url: `${model}`,
            data: {
                vehicle: vehicle.id,
                type: type,
                mileage: mileage
            }
        })
    }
}