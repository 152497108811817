import axios from '../index'

const model = 'users'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (id) => {
        return axios({
            url: `${model}/${id}`
        })
    },
    uploadProfilePicture: async (id, image) => {
        return axios({
            url: `${model}/${id}/profile_upload`,
            method: 'post',
            data: {
                file: image
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}