<template>
    <div class="v-input">
        <label v-if="!!label">{{ label }}<span v-if="isRequired" style="color: red">*</span></label>
        <input v-if="getType === 'text' || getType === 'password'"
               @input="updateValue($event.target.value)"
               :class="`form-control ${css}`"
               :disabled="disabled"
               :placeholder="placeHolderText? placeHolderText : label"
               :type="getType"
               ref="input"
               :value="value.value"/>
        <ul class="autocomplete">
            <li v-for="item in autoComplete" class="autocomplete__item" @click="autoCompleteSetValue(item[autoCompleteKey])">
                <span>{{ item[autoCompleteLabel] }}</span>
            </li>
        </ul>
        <baseInputError :show-errors="showErrors" :errors="errors"/>
    </div>
</template>

<script>
import baseInput from "@/components/shared/baseInput";
import baseInputError from "@/components/shared/baseInputError";

export default {
    name: 'vInput',
    props: {
        autoCompleteList: {
            required: false,
            type: Array
        },
        autoCompleteKey: {
            required: false,
            type: String,
            default: 'value'
        },
        autoCompleteLabel: {
            required: false,
            type: String,
            default: 'label'
        }
    },
    extends: baseInput,
    components: {
        baseInputError
    },
    data() {
        return {
            autoComplete: []
        }
    },
    watch: {
        'value': {
            handler(_new, _) {
                this.autocompleteCheck(_new.value)
            }
        },
        autoCompleteList: {
            handler(){
                this.autocompleteCheck(this.value.value)
            }
        }
    },
    methods: {
        autocompleteCheck(value){
            if (value !== null && value !== '' && value.length > 0 && this.autoCompleteList !== undefined) {
                this.autoComplete = this.autoCompleteList.filter((item) => {
                    return item[this.autoCompleteKey].toUpperCase().startsWith(value.toUpperCase()) && item[this.autoCompleteKey].length !== value.length
                })
            } else {
                this.autoComplete = []
            }
        },
        autoCompleteSetValue(value) {
            this.value.value = value
        }
    }
}
</script>

<style lang="scss">
$border: 1px solid #e1e5eb;
.v-input {
    position: relative;
}

.autocomplete {
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    top: 70px;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 10;

    &__item {
        border-left: $border;
        border-right: $border;
        border-bottom: $border;
        padding: 5px;
        cursor: pointer;
        background-color: #FFF;
    }
}
</style>
