export default [
  {
    title: 'Voorbeeld',
    htmlBefore: '<i class="material-icons">accessibility_new</i>',
    htmlAfter: '',
    items: [
      {
        title: 'Voorbeeld',
        route: 'sell-1',
      },
    ]
  }
]
