import axios from '../index'

const model = '/warranty_packages'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    get: async (warrantyPackage) => {
        return axios({
            url: `${model}/${warrantyPackage.id}`
        })
    },
    create: async (name, price) => {
        return axios({
            url: `${model}`,
            data: {
                name: name,
                price: price
            }
        })
    },
    delete: async (warrantyPackage) => {
        return axios({
            url: `${model}/${warrantyPackage.id}`,
            method: 'DELETE'
        })
    }
}