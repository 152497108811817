const userProfileIndex = () => import('@/modules/userProfile/index');
const home = () => import('@/modules/userProfile/pages/personalia');
const page2 = () => import('@/modules/userProfile/pages/page2');

export default {
  path: 'user-profile',
  roles: [
    "ADMIN"
  ],
  component: userProfileIndex,
  children: [
    {
      name: 'sell-1',
      path: '/',
      component: home,
    },
    {
      name: 'sell-2',
      path: 'step/2',
      component: page2,
    },
    {
      name: 'sell-3',
      path: 'step/3',
      component: page2,
    }
  ]
}
