<script>
export default {
  name: 'baseForm',
  props: {
    data: {
      required: false,
      default: {}
    },
    disabled: {
      required: false,
      default: false
    },
    showErrors: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      validations: null,
      form: null,
    }
  },
  mounted() {
    if (Object.keys(this.data).length === 0) {
      this.form = {}
    }
  },
  watch: {
    data: {
      handler: function (newValue, _) {
        if (!!newValue) {
          this.form = this.$v.inject(this.validations, newValue)
        }
      }
    }
  },
  methods: {
    valid(data) {
      this.$emit('valid', data)
    },
    setError(bool) {
      this.showErrors = bool
    },
    setValidator(validator) {
      this.validations = Object.assign({}, validator)
    },
    _resetForm() {
      if (this.form) {
        Object.keys(this.form).forEach((item) => {
          this.form[item].value = null
        })
      } else if (this.forms) {
        Object.keys(this.forms).forEach((item) => {
          this.forms[item].value = null
        })
      }
    },
    submit(data, resetForm, toastMessage) {
      if (resetForm) {
        this._resetForm();
      }
      if (toastMessage) {
        this.$toasted.show(toastMessage)
      }
      this.$emit('submit', data)
    }
  }
}
</script>
