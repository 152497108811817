import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        username: 'remco.verrips',
        firstName: 'Remco',
        lastName: 'Verrips',
        roles: [],
        profile_picture: 'https://avatars2.githubusercontent.com/u/2380089?s=460&u=0dc5024d775077737e68a6d6150ceb7d9ad75df9&v=4',
    },
    getters: {
        fullName: state => {
            return `${state.firstName} ${state.lastName}`
        },
        profilePicture: state => {
            return state.profile_picture
        },
        roles: state => {
            let roles = []
            state.roles.forEach((role) => {
                roles.push(role.replace('ROLE_', ''))
            })
            return roles
        }
    },
    actions: {
        async login({commit, state}, {username, password}) {
            const {status} = await Vue.prototype.$api.login(username, password)
            if (status === 200) {
                const {data} = await Vue.prototype.$api.me()
                if (data) {
                    commit('addUserProfile', data)
                    return true
                }
            }
            return false
        },
        async logout({commit}) {
            let response = await Vue.prototype.$api.logout()
            return (response.status === 200 && response.data.success)
        },
        setUser({state}, user) {
            state = user
        }
    },
    mutations: {
        addUserProfile: (state, {username, roles}) => {
            state.username = username
            state.roles = roles
        },
        addNotification: (state, notification) => {
            state.notifications.push(notification)
        },

    }
};
