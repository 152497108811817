import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// load all stores
let modules = {};
const plugins = require.context('./stores/', true, /.js$/);
plugins.keys().forEach(file => {
    modules[file.split('.')[1].split('/')[1]] = plugins(file).default
});

const moduleStores = require.context('../modules/', true, /.store.js$/);
moduleStores.keys().forEach(file => {
    let splitSlashes = file.split('/')
    let storeModuleName = `${splitSlashes[1]}.${splitSlashes[3].split('.')[0]}`
    modules[storeModuleName] = moduleStores(file).default
});

const store = () => {
    return new Vuex.Store({
        modules: modules,
        plugins: [createPersistedState()]
    })
};

export default store
