import axios from '../index'

const model = '/vehicle_option_groups'

export default {
    list: async () => {
        return axios({
            url: `${model}`
        })
    },
    // get: async (vehicleOptionCategory) => {
    //     return axios({
    //         url: `${model}?vehicle_option_category.id=${vehicleOptionCategory.id}`
    //     })
    // },
    getByCategory(vehicleOptionCategory){
        return axios({
            url: `${model}?vehicle_option_category.id=${vehicleOptionCategory.id}`
        })
    },
    create: async (name, description, ignored, vehicleOptionCategory) => {
        return axios({
            url: `${model}`,
            method: 'POST',
            data: {
                name: name,
                description: description,
                ignored: ignored,
                vehicle_option_category: `/vehicle_option_categories/${vehicleOptionCategory.id}`
            }
        })
    },
    delete: async (vehicleOptionGroup) => {
        return axios({
            url: `${model}/${vehicleOptionGroup.id}`,
            method: 'DELETE'
        })
    }
}
