<template>
  <div class="v-input">
    <label v-if="!!label">{{ label }}<span v-if="isRequired" style="color: red">*</span></label>
    <multiselect deselectLabel="Klik om te verwijderen"
                 v-if="options !== null"
                 @select="select"
                 :value="value.value"
                 :options="options"
                 :placeholder="placeHolderText"
                 :label="optionsValue"
                 :track-by="optionsKey">
      <div slot="noOptions">Lijst is leeg</div>
      <div slot="beforeList" style="padding: 10px">
        <slot name="beforeList"/>
      </div>
      <div slot="noResult">Geen resultaten gevonden</div>
    </multiselect>
    <p v-else>
      <span v-if="hasNoOptionsSlot"><slot name="noOptions"/></span>
      <span v-else>Word geladen...</span>
    </p>
    <baseInputError :show-errors="showErrors" :errors="errors"/>
  </div>
</template>

<script>
import baseInput from "@/components/shared/baseInput";
import baseInputError from "@/components/shared/baseInputError";

export default {
  name: 'vSelect',
  extends: baseInput,
  components: {
    baseInputError
  },
  computed: {
    hasNoOptionsSlot() {
      return !!this.$slots['noOptions']
    }
  },
  methods: {
    select(e) {
      this.value.value = e
    }
  },
  props: {
    defaultMessage: {
      required: false,
      default: 'Selecteer een optie'
    },
    options: {
      required: true
    },
    optionsKey: {
      required: true
    },
    optionsValue: {
      required: true
    }
  }
}
</script>

<style lang="scss">
.multiselect {
  min-height: 35px;
}

.multiselect__select {
  height: 34px;
}

.multiselect__select:before {
  top: 75%;
}

.multiselect__option--highlight {
  height: 35px;
  line-height: 35px;
}

.multiselect__placeholder {
  padding-top: 0;
  margin-bottom: 0;
}

.multiselect__tags {
  padding: 6px 40px 0 8px;
}

.multiselect__tags,
.multiselect__option {
  min-height: 35px;
}

.multiselect__option {
  line-height: 12px;
}

.multiselect__option > span {
  font-size: 0.8125rem;
}

.multiselect__input, .multiselect__single {
  font-size: 0.8125rem;
}

.multiselect__option--highlight::after {
  max-height: 35px;
}
</style>
