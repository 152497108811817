<template>
  <ul class="v-error" v-if="showErrors && errors.length > 0">
    <li v-for="error in errors">{{ error }}</li>
  </ul>
</template>

<script>
export default {
  name: 'baseInputError',
  props: {
    showErrors: {
      required: true,
    },
    errors: {
      required: true,
    }
  }
}
</script>