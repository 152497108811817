const index = () => import('@/modules/purchase/index');
const home = () => import('@/modules/purchase/pages/index');
const list = () => import('@/modules/purchase/pages/list');
const technicalVehicleInformationList = () => import('@/modules/purchase/pages/technicalVehicleInformationList');

export default {
  path: 'aankoop',
  roles: [
    "ADMIN"
  ],
  component: index,
  children: [
    {
      name: 'overview',
      path: '/',
      component: home,
    },
    {
      name: 'list',
      path: 'list',
      component: list,
    },
    {
      name: 'technicalVehicleInformationList',
      path: 'technische-voertuig-informatie/:vehicle_id',
      component: technicalVehicleInformationList,
    },
  ]
}
