import Vue from 'vue'

Vue.filter('currency', function (value, currency) {
  if (!currency) currency = 'EUR'
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: currency
  })
  return formatter.format(value)
})
