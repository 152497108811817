<template>
    <div style="display:flex; width: 100%">
        <ListLoader v-if="!Array.isArray(data)"/>
<!--        <input type="text" placeholder="search" v-model="search" v-show="Array.isArray(data) && (searchKeys.length>0)">-->
        <vue-table v-show="Array.isArray(data)"
                   ref="vuetable"
                   :api-mode="false"
                   :fields="fields"
                   :per-page="perPage"
                   :data-manager="dataManager"
                   :css="css.Table"
                   pagination-path="pagination"
                   :track-by="trackBy"
                   @vuetable:pagination-data="onPaginationData">
            <div slot="actions" slot-scope="props" style="display: flex">
                <button
                    v-for="action in actions"
                    v-if="action.hasOwnProperty('if') ? action.if(props.rowData) : true"
                    class="btn btn-primary vTable-button"
                    :class="getStyleType(action)"
                    @click="action.fn(props.rowData)">
                    <span v-html="action.icon"></span>
                </button>
            </div>
        </vue-table>
        <div class="vTable-paginator">
            <vue-table-pagination ref="pagination"
                                  @vuetable-pagination:change-page="onChangePage"
            ></vue-table-pagination>
        </div>
    </div>
</template>

<script>
import VueTable from 'vuetable-2'
import VueTablePagination from 'vuetable-2/src/components/VuetablePagination'
import {ListLoader} from 'vue-content-loader'

import _ from "lodash";

export default {
    name: 'vTable',
    components: {
        VueTable,
        VueTablePagination,
        ListLoader
    },
    data() {
        return {
            search: null,
            css: {
                Table: {
                    tableClass: 'ui celled table table-padding'
                }
            }
        }
    },
    props: {
        fields: {
            required: true,
            type: Array
        },
        perPage: {
            required: false,
            default: 10
        },
        actions: {
            required: true,
            type: Array
        },
        data: {
            required: false,
            type: Array,
            default: null
        },
        trackBy: {
            type: Object,
            default: 'id'
        },
        searchKeys: {
            type: Array,
        }
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        search: _.debounce(function (newVal) {
            this.searchValue(newVal)
        }, 250),
    },
    mounted() {
        // this.$refs.vuetable.refresh();
        this.copyData = this.data
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        dataManager(sortOrder, pagination) {
            if (!this.data) return;
            if (this.data.length < 1) return;

            let local = this.data;
            if (sortOrder.length > 0) {
                local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage
            );
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },
        getStyleType(action) {
            if (!action.hasOwnProperty('type')) return 'btn-primary'
            return `btn-${action.type}`
        },
        searchValue(newValue) {
            if (newValue) {
                let options = ['name', 'id']
                this.data = this.data.filter(make => {
                    let found = false
                    options.forEach(option => {
                        if (!found) {
                            found = make[option].toString().toLowerCase().includes(newValue.toLowerCase())
                        }
                    })
                    return found
                })
            } else {
                this.data = this.copyData
            }
        }
    }
}
</script>

<style type="text/css">
table {
    table-layout: fixed;
}

.vTable-paginator {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.vTable-paginator > div {
    margin: 0 auto;
}

button.ui.button {
    /*  padding: 8px 3px 8px 10px;*/
    /*  margin-top: 1px;*/
    /*  margin-bottom: 1px;*/
}

.pagination a {
    padding: 3px 10px;
    border: 1px solid gray;
}

.table thead th {
    border-top: none;
}

.vTable-button:not(:last-child) {
    margin: 0 10px 0 0;
}

.table-padding > thead > tr > th:nth-child(1),
.table-padding > tbody > tr > td:nth-child(1) {
    padding-left: 30px;
}

.table-padding > thead > tr > th:last-child,
.table-padding > tbody > tr > td:last-child {
    padding-right: 30px;
}

.vuetable-body tr:hover {
    background-color: #EFEFEF;
}
</style>
