import Vue from 'vue'

function _GetSidecodeLicenseplate(licensePlate) {
    var arrSC = []
    var scUitz = ''

    // Make sure page won't crash because no licenseplate is empty string
    if (typeof licensePlate === 'undefined') {
        return ''
    }
    if (licensePlate.length <= 0) {
        return ''
    }
    licensePlate = licensePlate.replace('-', '').toUpperCase()

    // 1 XX-99-99
    arrSC[0] = /^[a-zA-Z]{2}[0-9]{2}[0-9]{2}$/
    // 2 99-99-XX
    arrSC[1] = /^[0-9]{2}[0-9]{2}[a-zA-Z]{2}$/
    // 3 99-XX-99
    arrSC[2] = /^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$/
    // 4 XX-99-XX
    arrSC[3] = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}$/
    //  5 XX-XX-99
    arrSC[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[0-9]{2}$/
    // 6 99-XX-XX
    arrSC[5] = /^[0-9]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/
    // 7 99-XXX-9
    arrSC[6] = /^[0-9]{2}[a-zA-Z]{3}[0-9]{1}$/
    // 8 9-XXX-99
    arrSC[7] = /^[0-9]{1}[a-zA-Z]{3}[0-9]{2}$/
    // 9  XX-999-X
    arrSC[8] = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{1}$/
    // 10 X-999-XX
    arrSC[9] = /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{2}$/
    // 11 XXX-99-X
    arrSC[10] = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}$/
    // 12 X-99-XXX
    arrSC[11] = /^[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{3}$/
    // 13 9-XX-999
    arrSC[12] = /^[0-9]{1}[a-zA-Z]{2}[0-9]{3}$/
    // 14 999-XX-9
    arrSC[13] = /^[0-9]{3}[a-zA-Z]{2}[0-9]{1}$/

    // except licenseplates for diplomats
    scUitz = '^CD[ABFJNST][0-9]{1,3}$'

    for (var i = 0; i < arrSC.length; i++) {
        if (licensePlate.match(arrSC[i])) {
            return (i + 1)
        }
    }
    if (licensePlate.match(scUitz)) {
        return 'CD'
    }

    return false
}

function format(licensePlate) {
    let sideCode = _GetSidecodeLicenseplate(licensePlate)
    if (typeof licensePlate === 'undefined') {
        return ''
    }
    if (licensePlate.length <= 0) {
        return ''
    }
    licensePlate = licensePlate.replace('-', '').toUpperCase()

    if (sideCode <= 6) {
        return licensePlate.substr(0, 2) + '-' + licensePlate.substr(2, 2) + '-' + licensePlate.substr(4, 2)
    }
    if (sideCode === 7 || sideCode === 9) {
        return licensePlate.substr(0, 2) + '-' + licensePlate.substr(2, 3) + '-' + licensePlate.substr(5, 1)
    }
    if (sideCode === 8 || sideCode === 10) {
        return licensePlate.substr(0, 1) + '-' + licensePlate.substr(1, 3) + '-' + licensePlate.substr(4, 2)
    }
    if (sideCode === 11 || sideCode === 14) {
        return licensePlate.substr(0, 3) + '-' + licensePlate.substr(3, 2) + '-' + licensePlate.substr(5, 1)
    }
    if (sideCode === 12 || sideCode === 13) {
        return licensePlate.substr(0, 1) + '-' + licensePlate.substr(1, 2) + '-' + licensePlate.substr(3, 3)
    }
    return licensePlate
}


Vue.filter('licensePlate', function (value) {
    if (!value) return ''
    return format(value)
})


