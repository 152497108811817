import axios from 'axios';
import qs from 'qs'
import Vue from 'vue'

const axiosInstance = axios.create({
    baseURL: process.env[`VUE_APP_PHOTOSTUDIO_ENDPOINT`],
    withCredentials: false
})
const imageCount = 18

export default {
    camera: {
        getAll: async () => {
            return axiosInstance({
                url: `/camera/get-all`
            })
        }
    },
    plc: {
        turnLeft: async (degrees) => {
            return axiosInstance({
                url: `/plc/turn/left/${degrees}`
            })
        },
        turnRight: async (degrees) => {
            return axiosInstance({
                url: `/plc/turn/right/${degrees}`
            })
        },
        getPosition: async () => {
            return axiosInstance({
                url: `/plc/get-position`
            })
        }
    },
    program: {
        start: async () => {
            return axiosInstance({
                url: `/program/start/${imageCount}`,
            })
        },
        continue: async (programID) => {
            return axiosInstance({
                url: `/program/continue/${programID}`,
            })
        },
        notConfirmed: async () => {
            return axiosInstance({
                url: `/program/not-confirmed`,
            })
        },
        confirm: async (programID, licensePlate) => {
            return axiosInstance({
                url: `/program/confirm`,
                method: 'PUT',
                data: qs.stringify({
                    id: programID,
                    liceseplate: licensePlate.replaceAll('-', '')
                })
            })
        },
        delete: async (programID) => {
            return axiosInstance({
                url: `/program/delete/${programID}`,
                method: 'DELETE'
            })
        }
    },
    docker: {
        list: async () => {
            return axiosInstance({
                url: `/docker/`
            })
        },
        search: async (name) => {
            return axiosInstance({
                url: `/docker/search/${name}`
            })
        },
        restart: async (containerID) => {
            return axiosInstance({
                url: `/docker/restart/${containerID}`
            })
        },
        start: async (containerID) => {
            return axiosInstance({
                url: `/docker/start/${containerID}`
            })
        },
        stop: async (containerID) => {
            return axiosInstance({
                url: `/docker/stop/${containerID}`
            })
        },
        logs: async (containerID, amount) => {
            return axiosInstance({
                url: `/docker/logs/${containerID}/${amount}`
            })
        }
    },
    ftp: {
        delete: {
            interior: async (fileName) => {
                return axiosInstance({
                    url: `/ftp/delete/interior/${fileName}`,
                    method: 'DELETE'
                })
            },
            detail: async (fileName) => {
                return axiosInstance({
                    url: `/ftp/delete/detail/${fileName}`,
                    method: 'DELETE'
                })
            }
        },
        confirm: {
            interior: async (licensePlate) => {
                return axiosInstance({
                    url: `/ftp/confirm/interior`,
                    method: 'POST',
                    data: qs.stringify({
                        licenseplate: licensePlate
                    })
                })
            },
            detail: async (licensePlate) => {
                return axiosInstance({
                    url: `/ftp/confirm/detail`,
                    method: 'POST',
                    data: qs.stringify({
                        licenseplate: licensePlate
                    })
                })
            }
        }
    },
    // New @TODO add new endpoints
    process: {
        current: () => {
            return axiosInstance({
                url: `/process/`
            })
        },
        start: () => {
            return axiosInstance({
                url: `/process/start`
            })
        },
        finish: () => {
            return axiosInstance({
                url: `/docker/finish`
            })
        }
    },
    images: {
        start: () => {

        },
        confirm: () => {

        },
        //
        getOrder: () => {

        },
        setOrder: () => {

        }
    }
}
