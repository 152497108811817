<template>
  <div class="v-input">
    <d-form-select v-model="countryId" :options="countries" placeholder="Land"/>
  </div>
</template>

<script>
export default {
  name: 'vCountry',
  props: {
    label: {
      required: false,
      default: 'Land'
    },
    countryId: {
      required: false,
      default: 1
    }
  },
  data() {
    return {
      countries: [],
    }
  },
  created() {
    this.getCounties()
  },
  methods: {
    async getCounties() {
      let {data} = await this.$api.country.list()
      data.forEach((country) => {
        this.countries.push({value: country.id, text: country.name})
      })
    },
  }
}
</script>

<style lang="scss">
.v-input {
  margin-bottom: 10px;

  input.shake-error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
  }
}
</style>