export default [
  {
    title: 'Foto Studio',
    htmlBefore: '<i class="material-icons">camera_alt</i>',
    items: [
      {
        title: 'Voertuig status',
        route: 'vehicle-status',
      },
      {
        title: 'Photobooth 1.',
        route: 'photobooth-1',
      },
      {
        title: 'Photobooth 2.',
        route: 'photobooth-2',
      }
    ]
  }
]
