import Vue from "vue";
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import Config from '@/config'

if (process.env[`VUE_APP_PRODUCTION`] === 'true') {
    Sentry.init({
        Vue,
        dsn: Config.sentry.dsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
    });

    Sentry.setTag("environment", Config.sentry.environment);
}
