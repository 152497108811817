<template>
  <div class="v-input">
    <label v-if="!!label">{{ label }}<span v-if="isRequired" style="color: red">*</span></label>
    <d-checkbox @input="select" :checked="value.value" :disabled="disabled">
      {{ placeholderText }}
    </d-checkbox>
  </div>
</template>

<script>
import baseInput from "@/components/shared/baseInput";

export default {
  name: 'vCheckbox',
  extends: baseInput,
  methods: {
    select(e) {
      this.value.value = e
    }
  },
  props: {
    placeholderText: {
    }
  }
}
</script>
